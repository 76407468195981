.upload-page__upload-area {
  padding: 1rem;
  background-color: rgb(247 250 252);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: flex;
  margin-top: 2rem;
}

.upload-page__upload-area__text {
  --tw-text-opacity: 1;
  color: rgb(77 79 92 / 0.5);
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
  margin: 1rem 0;
}

.upload-page__upload-area__text__dragActive {
  opacity: 0;
}

.upload-page__list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.upload-page__next-button {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0;
  margin-top: 0.5rem;
}

.upload-page__list-item__file-name {
  color: rgb(0 47 108);
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}

.upload-page__dimensions {
  color: var(--blc-primary-color);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

.upload-page__loading-state__text {
  width: 100%;
  text-align: center;
}

.uploadPage__entry__error {
  background-color: rgb(255 101 101 / 0.25);
}

.upload-page__table {
  margin-top: 1rem;
}

.upload-page__table > tr:not(:first-child):not(:last-child) {
  border-bottom-width: 2px;
}

.upload-page__table > tr > td,
.upload-page__table > tr > th {
  padding: 1rem;
}

.upload-page__table-header {
  text-align: left;
  background-color: rgb(248 248 248);
  width: 100%;
  color: var(--blc-primary-color);
  text-transform: uppercase;
  font-weight: bold;
  height: 3rem;
}

.upload-page__remove-button {
  margin-left: auto;
}
