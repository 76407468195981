.admin_users_page__table > thead th {
    color: rgb(0 47 108 / 1);
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;
}

.admin_users_page__table > thead th,
.admin_users_page__table > tbody td {
    padding: 0 0.5rem;
}

.admin-users__modal-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-users__modal-content > p {
    padding-top: 20px;
}

.admin-users__load-more-button {
    width: 10rem;
    align-self: center;
    margin-top: 10px;
}

.admin-users__user-list-item {
    border-top-width: 1px;
    height: 65px;
}
.admin-users__user-list-item:hover {
    background-color: rgb(226 232 240);
}

.admin-users__user-list-item > div {
    flex: 1 1 0;
}

.admin-users__invoice-address-block {
    display: flex;
    align-items: center;
}

.admin-users__invoice-address--invisible {
    opacity: 0;
}

.admin-users__invoice-address--visible {
    opacity: 1;
}

.admin-users__verified-badge {
    color: rgb(0 47 108 / 1);
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 9999px;
    justify-content: center;
    cursor: default;
    width: 8rem;
    display: flex;
}

.admin-users__verified-badge--verified {
    background-color: rgb(225 250 237 / 1);
}

.admin-users__verified-badge--unverified {
    background-color: rgb(255 245 230 / 1);
}
