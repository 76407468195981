.material-selection {
  margin: 1rem 0;
}

.material-selection__materialSelection-customer {
  background-color: rgb(0, 47, 108);
  /* wird sonst durch Dropdown-option styles überschrieben  */
  color: white !important;
}

.material-selection__materialSelection-customer:hover {
  /* wird sonst durch Dropdown-option styles überschrieben  */
  color: #333 !important;
}
