.file-stats__container {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.file-stats__label {
  min-width: 10rem;
}

.file-stats__value {
  font-weight: 400;
  padding: 0.25rem 0;
}
