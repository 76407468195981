.preview-images {
  margin: 1rem;
  min-width: 10rem;
}

.preview-images__arrow-bidirectional-wrapper {
  position: relative;
}

.preview-images__arrow-bidirectional-wrapper > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
}

.preview-images__image-container {
  align-items: center;
  display: flex;
  height: 114px;
  margin: 1px auto;
  width: 114px;
}

.preview-images__image {
  height: inherit;
  margin: auto;
  object-fit: contain;
}

.preview-images > .preview-images__image-container + * {
  margin-top: 1rem;
}
