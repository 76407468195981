.button {
    align-items: center;
    border-width: 2px;
    border-radius: 0.15rem;
    border-color: #94C21A;
    background-color: #94C21A;
    color: rgba(255, 255, 255, 1);
    display: flex;
    font-size: .9375rem;
    justify-content: center;
    min-height: 3rem;
    min-width: 10rem;
    padding: 0.5rem 1rem;
}

.button + .button {
    margin-left: 1rem;
}

.button--secondary {
    background-color: white;
    color: #002F6C;
}

.button:disabled {
    background-color: lightgrey;
    border-color: lightgrey;
    color: #333;
    cursor: not-allowed;
}

.button--success:disabled {
    background-color: #99e3c4;
    border-color: #99e3c4;
    color: white;
}

.button--rounded {
    border-radius: 9999px;
}

.button--success {
    background-color: #00ba6c;
    border-color: #00ba6c;
}

.button--danger {
    border-color: #ff6565;
    background-color: white;
    color: #ff6565;
}
