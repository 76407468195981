.checkbox {
    display: flex;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;
    height: 30px;
    align-items: center;
}

.checkbox > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: white;
    border-width: 1px;
}

.checkbox--disabled {
    cursor: not-allowed;
}

.checkbox > input:checked ~ .checkbox__checkmark {
    border-width: 1px;
    border-color: #002f6c;
}

.checkbox__checkmark::after {
    border: solid #002f6c;
    border-width: 0 3px 3px 0;
    content: "";
    display: none;
    height: 13px;
    left: 9px;
    position: absolute;
    transform: rotate(45deg);
    top: 6px;
    width: 9px;
}


.checkbox > input:checked ~ .checkbox__checkmark:after {
    display: block;
}
