.customer_material {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  width: 100%;
}

.customer_material__action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.customer_material__delete-modal-text {
  --tw-text-opacity: 0.5;
  color: rgb(67 66 93 / 0.5);
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.customer_material__load-more-button {
  width: 10rem;
  align-self: center;
  margin-top: 10px;
}

.customer_material__add-material-modal {
  width: 600px;
}

.customer_material__material-table {
  table-layout: auto;
  margin-top: 1rem;
}

.customer_material__material-table_header {
  color: rgb(0 47 108);
  font-weight: 700;
  background-color: rgb(248 248 248);
  width: 100%;
}
.customer_material__material-table_header > td {
  text-transform: uppercase;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.customer_material__material-table_row {
  border-top-width: 1px;
  height: 5rem;
}
.customer_material__material-table_row:hover {
  background-color: rgb(226 232 240);
}
.customer_material__material-table_row > td {
  color: rgb(77 79 92);
}
