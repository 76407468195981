.checkout-steps {
    display: flex;
    justify-content: space-between;
}

.checkout-steps__connection {
    flex-grow: 1;
}

.checkout-steps__connection > div {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    height: 1.5rem;
    border-bottom-width: 1px;
    border-color: rgba(233, 233, 240, 1);
}

.checkout-steps__step {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.checkout-steps__step > * {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #94C21A;
}

.checkout-steps__circle {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-width: 1px;
    margin-bottom: 0.5rem;
    border-color: #94C21A;
}

.checkout-steps__step--done > * {
    color: #94C21A;
}

.checkout-steps__step--done > .checkout-steps__circle {
    border-color: #E4F0C5;
    background-color: #E4F0C5;
}

.checkout-steps__step--next > * {
    color: #002F6C;
}

.checkout-steps__step--next > .checkout-steps__circle {
    border-color: #002F6C;
}
