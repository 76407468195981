@tailwind base;

@tailwind components;

@tailwind utilities;

/**
 * TODO temporäre Lösung mit !important wieder entfernen
 */
.cc-flex\:important {
    display: flex !important;
}
