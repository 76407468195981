.success-page {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(0, 47, 108);
    font-size: 1.125rem;
}

.success-page > svg {
    color: #94C21A;
}

.success-page > div:nth-of-type(1) {
    margin-top: 1rem;
}

.success-page > div:nth-of-type(2) {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
}

.success-page a {
    font-weight: 700;
}
