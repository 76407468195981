.toggle {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 0.5rem;
}

.toggle > svg {
    width: 1rem;
    height: 1rem;
}
