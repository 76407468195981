.blank-page {
  background-color: rgb(0 47 108);
  overflow: hidden;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.blank-page__content {
  padding-left: 6rem;
  padding-right: 6rem;
  background-color: rgb(255 255 255);
  width: 620px;
  height: 740px;
  position: relative;
}

.blank-page__go-back {
  color: rgb(0 47 108);

  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 3rem;
  top: 0px;
  left: 0px;
  position: absolute;
}

.blank-page__go-back > svg {
  margin-right: 2;
}

.blank-page__logo-with-title {
  align-items: center;
  flex-direction: column;
  height: 100%;
  display: flex;
  margin-top: 10rem;
}

.blank-page__logo-with-title > svg {
  width: 350px;
}

.blank-page__logo-with-title > p {
  color: rgb(77 79 92 / 0.5);
  text-align: center;
  margin: 1rem 0 0 0;
}

.blank-page__logo-with-title > div {
  flex-direction: column;
  width: 80%;
  display: flex;
}

.blank-page__buttons {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 2.5rem;
}

.blank-page__links {
  text-decoration-line: underline;
  color: rgb(67 66 93);
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
  display: flex;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.blank-page__errors {
  color: rgb(255 101 101);
  vertical-align: middle;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: rgb(255 240 240);
  border-color: rgb(255 101 101);
  border-width: 2px;
  flex-direction: row;
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
}

.blank-page__errors > svg {
  padding-top: 0.25rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
