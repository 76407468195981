.customer-order-details-page__status-buttons {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2rem;
}

.customer-order-details-page__status-buttons > *:not(:last-child) {
    margin-right: 2rem;
}

.customer-order-details-page__withdraw-info {
    color: red;
    font-weight: bold;
}
