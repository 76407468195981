.menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.menu {
  background: #ffffff;
  position: absolute;
  top: 20px;
  right: 0;
  width: 200px;
  box-shadow:
    0 0 10px rgba(0, 0, 0, 0.06),
    0 4px 4px rgba(0, 0, 0, 0.12);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.menu--vertical {
  font-size: large;
  font-weight: bold;
  writing-mode: vertical-lr;
  padding: 5px;
  width: 25px;
  height: 40px;
  line-height: 1.4;
}

.menu--vertical:hover {
  background-color: #d3d3d3;
  box-shadow: 0 0 2px grey;
}

.menu.active {
  opacity: 1;
  visibility: visible;
}

.menu ul {
  list-style: none;
}

.menu li {
  cursor: pointer;
  padding: 1rem;
  text-align: center;
}

.menu li:not(:last-child) {
  border-bottom: rgb(226 232 240) solid 1px;
}

.menu li:hover {
  background-color: #f2faff;
}
