.modal {
  overflow-x: auto;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 50;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
}

.modal_container {
  width: auto;
  margin: 1.5rem auto;
  position: relative;
  max-width: 100vw;
  max-height: calc(100vh - 3rem);
  overflow-x: hidden;
  overflow-y: auto;
}

.modal_inner_container {
  box-shadow:
    rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(0, 0, 0, 0.1) 0 10px 15px -3px,
    rgba(0, 0, 0, 0.1) 0 4px 6px -4px;

  background-color: rgb(255 255 255);
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal_content-header {
  color: rgb(67 66 93);
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  flex: 1 1 auto;
  position: relative;
}

.modal_content-header > h3 {
  color: rgb(77 79 92);
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
  font-weight: inherit;
}

.modal_content-container-text {
  color: rgb(67 66 93);
  padding: 1.5rem;
  flex: 1 1 auto;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.modal_content-container-text > p {
  color: rgb(67 66 93/ 0.5);
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.modal_actions {
  padding: 1.5rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.with_cancel {
  justify-content: space-between;
}

.modal-backdrop {
  opacity: 0.25;
  background-color: rgb(0 0 0);
  z-index: 40;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
}
