.navigation-background {
  background-color: rgb(0 47 108);
  width: 16rem;
}

.navigation_item {
  color: rgb(255 255 255);
  padding: 1rem 2rem;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  display: flex;
}

.navigation_item-active {
  cursor: default;
  background-color: rgb(228 233 239);
  color: rgb(0 47 108);
}

.navigation_item > svg {
  margin-right: 1rem;
}
