@media only screen {
    .order-print-page {
        display: none;
    }
}

@media print {
    @page {
        size: A4;
        margin: 0;
    }
    html, body {
        width: 210mm;
        height: 282mm;
        font-size: 11px;
        background: #FFF;
        overflow: visible;
    }
    body {
        padding: 15mm;
    }
}

.order-print-page__header {
    display: flex;
}

.order-print-page__header > div:first-child {
    flex-grow: 1;
}

.order-print-page__logo {
    display: flex;
    justify-content: flex-end;
    color: #002F6C;
}

.order-print-page__company-address--dense {
    font-size: 0.75rem;
    color: #002F6C;
}

.order-print-page__address {
    display: flex;
}

.order-print-page__address > address {
    font-style: normal;
    width: 50%;
}

.order-print-page__address > address > span {
    font-style: italic;
    font-size: 75%;
}

.order-print-page__order-details {
    width: 100%;
}

.order-print-page__offer-title {
    display: block;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.order-print-page__order-table {
    margin-bottom: 1.5rem;
    width: 100%;
}

.order-print-page__order-table > thead {
    height: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    vertical-align: baseline;
}

.order-print-page__article-column {
    width: 46%;
}

.order-print-page__amount-column {
    text-align: center;
    width: 7%;
}

.order-print-page__price-column {
    text-align: right;
}

.order-print-page__order-row {
    page-break-inside: avoid;
}

.order-print-page__order-article-cell {
    display: flex;
}

.order-print-page__order-article-cell > div + div {
    padding-left: 3rem;
    padding-top: 1rem;
}

.order-print-page__order-file-detail {
    padding: 0.25rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 270px;
}

.order-print-page__order-row-amount {
    vertical-align: top;
    text-align: center;
}

.order-print-page__order-row-price {
    vertical-align: top;
    text-align: end;
}

.order-print-page__shipping-date > span + span {
    margin-left: 1.5rem;
}

.order-print-page__pickup {
    margin-top: 1.5rem;
}

.order-print-page__prices {
    page-break-inside: avoid;
    display: flex;
    justify-content: flex-end;
}

.order-print-page__prices_position {
    padding-right: 1rem;
    text-align: right;
}

.order-print-page__prices_position + td {
    text-align: right;
}

.order-print-page__footer {
    page-break-inside: avoid;
    font-size: 0.75rem;
    padding-top: 2.5rem;
}
