.page {
    overflow-x: hidden;
    overflow-y: scroll;
    flex-direction: column;
    height: 100%;
    display: flex;
}

.page__area {
    flex-direction: row;
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
}

.page__wrapper {
    background-color: rgb(228 233 239);
    overflow-y: auto;
    flex: 1 1 0;
    display: flex;
}

.page__main {
    display: flex;
    flex: 1 1 0;
    padding-top: 1.3rem;
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: rgb(228 233 239 / 1);
    flex-direction: column;
}

.page__title {
    color: rgb(67 66 93 / 1);
    line-height: 1rem;
    font-size: 1.25rem;
    padding-bottom: 1rem;
    align-items: center;
    flex-grow: 0;
    display: flex;
}

.page__content {
    border-color: #8080803b;
    border-width: 2px;
    padding: 2rem;
    background-color: rgb(255 255 255 / 1);
    flex-direction: column;
    flex: 1 1 0;
    display: flex;
    margin-bottom: 2rem;
}
