.language-selector {
    cursor: pointer;
}

.language-selector > span:not(:last-child):after {
    content: '|';
    padding: 0 0.2rem;
}

.language-selector__item--selected {
    text-decoration: underline;
}
