.input__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input__wrapper > input:disabled {
    background-color: lightgrey;
}

.input__wrapper > input {
    appearance: none;
    color: #333;
    line-height: 1.25;
    width: 100%;
    border-bottom-width: 1px;
    border-color: rgba(233, 233, 240, 1);
    border-style: solid;
    margin: 1rem 0;
    height: 38px;
}

.input__wrapper > .input__rounded {
    padding: 0.25rem 0.5rem;
    margin: 0;
    border-width: 1px;
    border-radius: 1px;
    border-color: #ccc;
}

.input__wrapper.error > input, .input__wrapper.error > input::placeholder, .input__error {
    color: rgba(255, 101, 101, 1);
}

.input__wrapper > input:focus {
    outline: 0;
}

.input__error {
    text-align: left;
    width: 100%;
    margin-top: -10px;

}
