.date-input {
    display: flex;
    flex-direction: column;
    border-bottom-width: 1px;
    padding-top: 0.5rem;
}

.date-input > label {
    color: #002F6C;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700;
}

.date-input__input--picker {
    cursor: pointer;
}

.date-input__input {
    width: 12rem;
    border: 0;
    outline: none;
    padding: 0.25rem 0;
    margin-top: 1rem;
    height: 38px;
}

.date-input__input--hidden {
    width: 0;
    height: 0;
}
