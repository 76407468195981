.welcome-page__content {
    display: flex;
    flex-direction: column;
    width: 600px;
}

.welcome-page__find-us-option, .welcome-page__find-us-option > div {
    width: 100%;
}

.welcome-page__find-us-option > button {
    margin-top: 2.5rem;
}
