.order-total {
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
}

.order-total th {
    color: rgba(0, 47, 108);
    text-align: right;
}

.order-total td {
    width: 8rem;
    text-align: right;
}

.order-total__total {
    font-weight: 700;
}

.order-total__total > td:nth-of-type(2) {
    color: #94C21A;
}

.order-total__cost_manually div {
    margin-top: 1rem;
}
