.admin_orders_page__search {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.admin_orders_page__search > input {
    border-style: solid;
    border-width: 2px;
}

.admin_orders_page__load-more-button {
    width: 10rem;
    align-self: center;
    margin-top: 10px;
}

.admin_orders_page__table > thead th {
    color: rgb(0 47 108 / 1);
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;
}

.admin_orders_page__table > thead th,
.admin_orders_page__table > tbody td {
    padding: 0 0.5rem;
}

.admin_orders_page__table > thead th:last-child {
    text-align: center;
}

.admin_orders_page__table > tbody td {
    color: rgb(77 79 92 / 1);
}

.admin_orders_page__table > tbody tr {
    border-top-width: 1px;
    height: 5rem;
}

.admin_orders_page__table > tbody tr:hover {
    background-color: rgb(226 232 240 / 1);
}

.admin_orders_page__table > tbody td:nth-child(7),
.admin_orders_page__table > tbody td:nth-child(9) {
    font-weight: 700;
}
