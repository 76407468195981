.parameters-page__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.parameters-page__upload-csv {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  margin-top: 0.5rem;
}

.parameters-page__parts-header {
  color: rgb(77 79 92);
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 1rem;
}

.parameters-page__list {
  display: flex;
  flex-direction: column;
}

.parameters-page__list-item {
  padding: 0.5rem 0;
}

.parameters-page__list-item + .parameters-page__list-item {
  border-top-width: 2px;
}

.parameters-page__list-item-header {
  display: flex;
  padding-bottom: 0.5rem;
  color: #4d4f5c;
}

.parameters-page__list-item-header > span {
  width: 2rem;
  font-weight: bold;
}

.parameters-page__list-item-header > span + div {
  flex-grow: 1;
  font-weight: bold;
}

.parameters-page__label {
  display: flex;
  align-items: center;
}

.parameters-page__file-selection {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

.parameters-page__file-selection-images {
  width: 114px;
}

.parameters-page__file-selection-quantity {
  padding: 1.5rem 0;
}

.parameters-page__file-selection-list-item-actions {
  grid-area: actions;
  align-items: center;
  display: flex;
  height: 3rem;
}

.parameters-page__file-selection-list-item-actions > * {
  margin-right: 0.25rem;
}

.parameters-page__file-selection-list-item {
  padding: 1rem 0;
}

.parameters-page__document-upload-tooltip {
  align-items: center;
}

.parameters-page__file-selection-list-item > span {
  position: relative;
  left: -2.3rem;
  font-weight: bold;
  color: #4d4f5c;
}

.parameters-page__part-selection {
  display: grid;
  grid-template-columns: 192px 0.8fr 0.8fr 0.8fr;
  grid-template-areas:
    'image type thickness material'
    'image rotation rotationangle bendandround'
    'image actions actions actions';
}

.parameters-page__part-selection > div:not(:nth-child(1)) {
  padding: 1rem 2rem;
}

.parameters-page__part-selection-image {
  grid-area: image;
}

.part-viewer-button > * {
  margin-bottom: 1rem;
  width: 100%;
}

.parameters-page__part-selection-rotationangle > input:disabled {
  background-color: lightgrey;
  color: lightgrey;
}

.parameters-page__part-selection-bendandround {
  display: flex;
  grid-area: bendandround;
}

.parameters-page__part-selection-bendandround > div {
  display: flex;
  align-items: center;
  padding-top: 1.5rem;
}

.parameters-page__part-selection-bendandround > div + div {
  padding-left: 1rem;
}

.parameters-page__part-selection-materialSelection-customer {
  background-color: rgb(0, 47, 108);
  /* wird sonst durch Dropdown-option styles überschrieben  */
  color: white !important;
}

.parameters-page__part-selection-materialSelection-customer:hover {
  /* wird sonst durch Dropdown-option styles überschrieben  */
  color: #333 !important;
}

.parameters-page__file-selection-list-item-actions__error {
  color: red;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.customer_material_page__csv-error-table {
  width: 80vw;
}

.customer_material_page__csv-error-table-container {
  max-height: 50vh;
  overflow: auto;
}
.customer_material_page__csv-error {
  color: red !important;
  font-weight: bold;
}

.button + .button.parameters-page__file-selection-list-item-actions__remove,
.parameters-page__file-selection-list-item-actions__remove {
  margin-left: auto;
}

.parameters-page__working-steps-modal {
  width: 50rem;
}

.parameters-page__working-steps-modal .Dropdown-menu {
  position: initial;
}