.part-viewer-modal {
  width: 90vw;
  min-height: 82vh;
}

.part-viewer {
  display: flex;
}

.part-viewer__viewer {
  position: relative;
  background: #002f6c;
  height: 65vh;
  flex-grow: 1;
  width: 55%;
}

.part-viewer > * + * {
  margin-left: 1rem;
}

.part-viewer__analysis-details {
  width: 45%;
  height: 65vh;
  overflow-y: auto;
}

.part-viewer__part-analysis__no-issue {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: bold;
  font-size: 2rem;
  color: #94C21A;
}

.part-viewer__part-analysis:not(:last-child) {
  border-bottom: 2px solid lightgrey;
}

.part-viewer__viewer-logo-overlay {
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 48px;
  height: auto;
  opacity: 0.4;
  color: white;
}

.part-viewer__viewer-legend-overlay {
  position: absolute;
  right: 8px;
  bottom: 8px;
  color: white;
  font-size: 0.75rem;
}

.part-viewer__viewer-legend-overlay > p {
  display: flex;
  align-items: center;
}

.part-viewer__analysis-table {
  width: 100%
}

.part-viewer__analysis-table thead > tr {
  color: rgb(0 47 108);
  font-weight: 700;
  background-color: rgb(248 248 248);
  text-transform: uppercase;
  text-align: left;
}

.part-viewer__analysis-table tbody > tr {
  border-top-width: 1px;
}

.part-viewer__analysis-table tbody > tr:hover {
  background-color: rgb(226 232 240 / 1);
}

.part-viewer__analysis-table thead > tr th,
.part-viewer__analysis-table tbody > tr td {
  padding: 0 1rem;
}

.part-viewer__part-analysis-issue {
  margin-bottom: 0.5rem;
}

.part-viewer__part-analysis-issue:not(:last-of-type(.part-viewer__part-analysis-issue)) {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 1rem;
}

.part-viewer__part-analysis-issue > div {
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1rem;
}

.part-viewer__part-analysis-notes {
  background-color: orange;
  border-radius: 0.15rem;
  padding: 0.25rem;
}

.part-viewer__toggle-button {
  min-height: 0;
  min-width: 2rem;
  padding: 0;
  border: 0;
  background-color: unset;
  color: #43425D;
}

.part-viewer__toggle-button__visible {
  color: #94C21A;
}
