.admin-order-details-page__status-buttons {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    align-items: center;
}

.admin-order-details-page__status-buttons > *:first-child {
    margin-right: 2rem;
}

.admin-order-details-page__delivery-dates {
    display: flex;
}

.admin-order-details-page__delivery-dates > *:first-child {
    margin-right: 2rem;
}

.admin-order-details-custom-shipping-cost-label {
    color: rgb(0 47 108);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 1rem;
}

.admin-order-details-custom-shipping-cost {
    margin-top: 30px;
    width: 192px;
}
