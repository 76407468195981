.order-page__delivery_dates {
  display: flex;
}

.order-page__delivery_dates > div + div {
  margin-left: 2rem;
}

.order_page__actions {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.order_page__actions > div {
  display: flex;
}

.order-page__table-body > tr > td:first-of-type {
  padding-left: 1rem;
}
