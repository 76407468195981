.user-settings-page {
  margin: -32px;
  flex: 1 1 0%;
  display: flex;
}

.user-settings-page-setting_menu {
  display: flex;
  flex-direction: column;
  border-right-width: 2px;
  width: 25%;
}
.user-settings-page-setting_menu_selection {
  flex: 1 1 0%;
  width: 75%;
  display: flex;
}

.user-settings-page__menu-item {
  display: flex;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.user-settings-page__menu-item + .user-settings-page__menu-item {
  border-top-width: 2px;
}

.user-settings-page__menu-item--active {
  background-color: rgb(250 250 250);
  color: rgb(0 47 108);
  font-weight: 700;
}
