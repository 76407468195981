.filter-tiles > .filter-tiles__tile--selected {
    color: black;
}

.filter-tiles {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.filter-tiles > div {
    border-width: 2px;
    cursor: pointer;
    color: rgb(180 180 180 / 1);
    padding: 0.5rem 1.25rem;
}
