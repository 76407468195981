:root {
  --blc-primary-color: rgb(0 47 108);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #4d4f5c;
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

body {
  font-size: 14px;
  line-height: 1.42857143;
  font-family: Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
}

.page-content {
  padding-left: 4rem;
  padding-right: 4rem;
}

@media (min-width: 1280px) {
  .page-content {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (min-width: 1600px) {
  .page-content {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

@page {
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 2.7cm auto 2.5cm;
}

/* see https://github.com/hacknug/tailwindcss-break/tree/ee8b58b67c9533b7629d10ee281ff10acc663326 */
.bi-avoid {
  page-break-inside: avoid;
}

.Toastify__toast {
  border-radius: 0;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.Dropdown-disabled {
  background-color: lightgrey;
  cursor: not-allowed;
}

.width-230 {
  width: 230px;
}

.width-350 {
  max-width: 350px;
}

.app_router {
  height: 100%;
}
