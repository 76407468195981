.address_type_selection-remove_address_modal-text {
  color: rgb(67 66 93 / 0.5);
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.address_type_selection-address_selection_menu {
  display: flex;
  border-right-width: 2px;
  flex-direction: column;
  width: 33.333333%;
}

.address_type_selection-address_selection_menu_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-bottom-width: 2px;
}
.address_type_selection-address_selection_menu_item > p {
  color: rgb(77 79 92);
}

.address_type_selection-address_selection_menu_item-selected {
  background-color: rgb(250 250 250);
}

.address_type_selection-address_selection_menu_item-selected > p {
  font-weight: 700;
  color: rgb(0 47 108);
}

.address_type_selection-address_selection_menu_item-add_button-container {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}

.address_type_selection-address_form_container {
  display: flex;
  width: 66.666667%;
  margin: 1.5rem 3rem 3rem 3rem;
}

.address_type_selection-address_form-success_message_container {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0%;
  display: flex;
}

.address_type_selection-address_form-success_message_container > p {
  color: rgb(0 47 108);
  margin-top: 1rem;
}
