.top-navigation {
    padding: 0.5rem 8rem 0.5rem 1.5rem;
    background-color: rgb(255 255 255 / 1);
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    z-index: 10;
    top: 0;
    position: sticky;
}

.top-navigation__logo {
    justify-content: flex-start;
    flex: 1 1 0;
    display: flex;
    width: 230px;
}

.top-navigation__logo > svg {
    width: 230px;
}

.page__top-navigation__actions {
    align-items: center;
    display: flex;
}
