.not-found-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(228, 233, 239);
}

.not-found-page > span:nth-of-type(1) {
    color: rgba(255, 255, 255);
    font-size: 8rem;
    font-weight: 900;
}

.not-found-page > span:nth-of-type(2) {
    color: rgba(0, 47, 108);
    font-size: 1.5rem;
    font-weight: 900;
}

.not-found-page > span:nth-of-type(3) {
    color: rgba(180, 180, 180);
    margin-top: 3rem;
    margin-bottom: 3rem;
}
