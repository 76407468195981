.status-badge {
    color: #002F6C;
    color: rgba(0, 47, 108, 1);
    padding: 0.5rem 1.5rem;
    border-radius: 9999px;
    display: flex;
    font-size: 0.75rem;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 8rem;
}

.status-badge--draft {
    background-color: rgba(225, 242, 250);
}

.status-badge--submitted {
    background-color: rgba(255, 245, 230);
}

.status-badge--confirmed {
    background-color: rgba(225, 250, 237);
}

.status-badge--rejected, .status-badge--canceled, .status-badge--withdraw {
    background-color: rgba(255, 237, 237);
}
